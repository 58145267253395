  <template>

    <!-- <div :class="{'toggle':dark==false, 'toggle night':dark==true}" >
      <svg v-if="dark"  class="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8212 11.6424C14.555 12.3914 13.0777 12.8212 11.5 12.8212C6.80558 12.8212 3 9.01562 3 4.3212C3 2.7435 3.42984 1.26619 4.1788 0C1.67746 1.47955 0 4.20448 0 7.3212C0 12.0156 3.80558 15.8212 8.5 15.8212C11.6167 15.8212 14.3416 14.1437 15.8212 11.6424Z" fill="#18CDB2"/>
      </svg>
      <button :class="{'round':!dark, 'round right':dark}" @click="toggleit"></button>
      <svg v-if="!dark" class="icon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2V0H7V2H8ZM2 2L2.70711 1.29289L4.12132 2.70711L3.41421 3.41421L2 2ZM4 7.5C4 9.433 5.567 11 7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5ZM7.5 3C5.01472 3 3 5.01472 3 7.5C3 9.98528 5.01472 12 7.5 12C9.98528 12 12 9.98528 12 7.5C12 5.01472 9.98528 3 7.5 3ZM15 8H13V7H15V8ZM0 8H2V7H0V8ZM12 3.41406L11.2929 2.70696L12.7071 1.29274L13.4142 1.99985L12 3.41406ZM11.7071 11.2929L11 12L12.4142 13.4142L13.1213 12.7071L11.7071 11.2929ZM3 13.4141L2.29289 12.707L3.70711 11.2927L4.41421 11.9998L3 13.4141ZM8 15V13H7V15H8Z" fill="white"/>
      </svg>
    </div> -->
    <FAButton :icon=icon :background="{'white':!dark, 'light':dark}"  @click="toggleit"  />
  </template>
  
  <script>
import FAButton from './FAButton.vue';

    export default {
      name: 'ToggleMode',
      data() {
        return {
          dark: false,
          icon: "mdi_moon"
        };
      },
      components:{
        FAButton
      },
      mounted() { // Cycle de vie du composant.
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Appel initial pour définir la largeur correcte
      },
        beforeUnmount() {
          window.removeEventListener('resize', this.handleResize);
      },
      methods: {
        
        handleResize() {
          this.updateIconPaths();
        },
        toggleit() {
          // <style>
          // </style>
          this.dark = !this.dark; // (this.dark==false)? true:false;
          this.icon = (this.dark)?"mdi_sun":"mdi_moon";
          // this.updateIconPaths(this.dark);
          const gif = document.getElementsByClassName('form-gif')[0];
          var isgif = gif!=null|| gif!=undefined;

          if (this.dark) {
            this.setDarkModeColors();
            if(isgif){
              gif.style.mixBlendMode = 'normal';
              gif.style.opacity= 0.1;
            }
            // document.body.classList.add('darkmode');
          } else {
            this.setLightModeColors();
            if(isgif){
              gif.style.mixBlendMode = 'multiply';
              gif.style.opacity= 1;
            }
            // document.body.classList.remove('darkmode');
          }
          this.$emit('darkmode', this.dark);
        },
        setDarkModeColors() {
          document.documentElement.style.setProperty('--surface-bright', '#3F4946');
          document.documentElement.style.setProperty('--on-surface', '#F4FBF8');
          document.documentElement.style.setProperty('--on-surface-var', '#e3eae793');
          document.documentElement.style.setProperty('--surf-container-low', '#171D1B');
          document.documentElement.style.setProperty('--secondary', '#DDE4E1');
          document.documentElement.style.setProperty('--primary', '#9FF2E2');
          document.documentElement.style.setProperty('--primary-container', '#006B5F');
          document.documentElement.style.setProperty('--surf-container', '#3F494659');
          document.documentElement.style.setProperty('--surf-container-high', '#171D1B25');
          document.documentElement.style.setProperty('--surf-container-high-50', '#171D1B25');
          document.documentElement.style.setProperty('--surf-container-highest', '#28302d85');
          document.documentElement.style.setProperty('--outline', '#E3EAE7');
          document.documentElement.style.setProperty('--accent-color', '#ff7769');
        
          // document.documentElement.style.setProperty('--tertiary-color', '#0e1e1b');
          // document.documentElement.style.setProperty('--white', '#024a42');
          // document.documentElement.style.setProperty('--tertiary-color-90', '#0e1e1be3');
          // // document.documentElement.style.setProperty('--accent-color', '#ff5722');
          // document.documentElement.style.setProperty('--dark-color', '#DDFFF4');
          // document.documentElement.style.setProperty('--darkcyan', '#DDFFF4');
          // document.body.style.background = '#0E1E1B';
        },
        setLightModeColors() {
          document.documentElement.style.setProperty('--surface-bright', '#F4FBF8');
          document.documentElement.style.setProperty('--on-surface', '#171D1B');
          document.documentElement.style.setProperty('--on-surface-var', '#3F4946');
          document.documentElement.style.setProperty('--surf-container-low', '#EFF5F2');
          document.documentElement.style.setProperty('--secondary', '#4A635E');
          document.documentElement.style.setProperty('--primary', '#006B5F');
          document.documentElement.style.setProperty('--primary-container', '#9FF2E2');
          document.documentElement.style.setProperty('--surf-container', '#E9EFEC');
          document.documentElement.style.setProperty('--surf-container-high', '#E3EAE7');
          document.documentElement.style.setProperty('--surf-container-high-50', '#E3EAE793');
          document.documentElement.style.setProperty('--surf-container-highest', '#DDE4E1');
          document.documentElement.style.setProperty('--outline', '#6F7976');
          document.documentElement.style.setProperty('--accent-color', '#F93E2B');

          // document.documentElement.style.setProperty('--primary-color', '#9FF2E2');
          // document.documentElement.style.setProperty('--primary-color-60', '#9ff2e1a0');
          // document.documentElement.style.setProperty('--secondary-color', '#159B8B');
          // document.documentElement.style.setProperty('--tertiary-color', '#DDFFF4');
          // document.documentElement.style.setProperty('--white', '#ffffff');
          // document.documentElement.style.setProperty('--tertiary-color-90', '#DDFFF4e3');
          // // document.documentElement.style.setProperty('--accent-color', '#F93E2B');
          // document.documentElement.style.setProperty('--dark-color', '#0E1E1B');
          // document.documentElement.style.setProperty('--darkcyan', '#024a42');

          // document.body.classList.remove('darkmode');
          // document.body.style.background = '#DDFFF4';

        },
        updateIconPaths(idark) {
          // console.log('huhu');
          const icons = document.getElementsByClassName('mdi_icon');
          
          Array.from(icons).forEach(icon => {
            const icname = icon.getAttribute('icon');

            // Extraire le nom de l'icône et l'extension du chemin actuel
            // const pathParts = currentSrc.split('/');
            // const fileName = pathParts.pop();
            // const baseName = fileName.split('.')[0]; // Nom de fichier sans extension
            // const extension = fileName.split('.').pop(); // Extension
            // "../../../public/assets/icons/${icon}.png`"
            // Modifier le chemin en fonction du mode sombre
            // || && !icname.includes('neg/') 
            // icname.replace('neg/','');
            // console.log(icname+" ");
            const folder = idark ? 'neg' : ''; // Ajouter /neg/ pour le mode sombre
            const newPath = `assets/icons/${folder}/${icname.replace('neg/','')}.png`;

            // Re-construire le chemin complet
            // pathParts.push(newPath);
            // const newSrc = pathParts.join('/');
            // console.log(" huhu "+currentSrc+" ");
            // console.log(newPath);
            // Mettre à jour le chemin de l'image
            icon.setAttribute('src', newPath);
              });
        }
          // console.log(this.dark,'huh');
      },
    //   computed: {
    //   dark: {
    //     get() {
    //       return this.value;
    //     },
    //     set(val) {
    //       this.$emit('input', val); // Propagation du v-model
    //     }
    //   }
    // }
    };
  </script>
  
  <style scoped>
  button{
    border: none;
  }
  .toggle{
    /* transition-duration: 0.5s; */
    animation: ease-in-out;
      width: 64px;
      height: 24px;
      background: var(--primary-color);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    } 
    .night{
      background: var(--darkcyan);
    }
    .icon{
      margin-right: 6px;
      margin-left: 6px;
    }
    .round{
      margin-left:3px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: var(--white);
      box-shadow: 4px 4px 4px var(--dark-color-25);
    }
    .right{
      margin-right:3px;
      transform: translateX(0%);
    }
  </style>
  