import { createApp } from 'vue'
import App from './App.vue'
// import i18n from './locales/i18n';
import i18n from "./locales";

// import { create } from 'core-js/core/object';
import VueScrollTo from 'vue-scrollto';

import router from './router/router'; // Import du router
import VueLazyload from 'vue-lazyload';

// createApp(App).mount('#app')
const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(VueScrollTo);
app.use(VueLazyload);
app.mount('#app');