<template>
  <!-- <div class="fullscreen" @touchstart="startTouch" @touchmove="moveTouch"></div> -->
    <div class="menu-wrapper" >
      <MDIcon icon='picto' class='picto' />
      <div>
        <!-- Bouton burger -->
        <button @click="toggleMenu" class="burger-button">
          <div class='line1'></div>
          <div class='line2'></div>
          <!-- ☰ -->
        </button>

        <!-- Menu dropdown-->
        <div id='dropdown'  >
            <!-- {{ tmode }} -->
            <div v-if="isMenuOpen" class="dropdownMenu" :class="{ 'rowlinks': tmode }">
              <ul >
                <li> <router-link class="link" @click="toActive('home')" to="/home"><MDIcon :icon='homeIcon' :icwidth=16 />  {{  $t('home') }} </router-link></li>
                <li><router-link class="link" @click="toActive('service')" to="/service"><MDIcon :icon='serviceIcon' :icwidth=24 />  {{  $t('servicesMenu') }} </router-link></li>
                <li> <router-link class="link" @click="toActive('career')" to="/career"><MDIcon :icon='careerIcon' :icwidth="18" />  {{  $t('careers') }} </router-link></li>
                <!-- <li> <router-link class="link" @click="toActive('about')" to="/about"><MDIcon :icon='aboutIcon' :icwidth="17"  />  {{  $t('about') }} </router-link></li> -->
                <li class="primary-active">
                  <router-link id="whitetext" @click="toActive('getTheApp')" to="/getTheApp"><MDIcon :icon='getTheAppIcon' />  {{  $t('getTheApp') }} </router-link>
                </li>
                <LanguageSelector :mmode=mmode :tmode=tmode class='ls-burgered' />

                <!-- <li><a  @click="toActive('home')" href="home"><MDIcon :icon='homeIcon' :icwidth=16 />Home</a></li>
                <li><a  @click="toActive('service')" href="services"><MDIcon :icon='serviceIcon' :icwidth=24 />Services</a></li>
                <li><a  @click="toActive('career')" href="careers"><MDIcon :icon='careerIcon' :icwidth="18" />Careers</a></li>
                <li><a  @click="toActive('about')" href="about"><MDIcon :icon='aboutIcon' :icwidth="17" />About</a></li> -->
              </ul>
            <!--hidden <button @click="toggleMenu" class="close-button">✖</button> -->
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import LanguageSelector from './LanguageSelector.vue';
import MDIcon from './MDIcon.vue';

export default {
  name: 'MenuBurger',
  components:{
    MDIcon,
    LanguageSelector
  },
  props:{
    wsmode:{
        type: Boolean,
        default: false
      },
    tmode:{
        type: Boolean,
        default: false
      },
      mmode:{
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      isMenuOpen: false,
      homeIcon:'home',
      serviceIcon: 'hand',
      careerIcon: 'career',
      aboutIcon: 'about',
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  computed:{
    getTheAppIcon() {
      return 'mdi_download';
    },
  },
  methods: {
    toActive(element) {
      
      console.log(this.dark);
      // console.log(this.homeIcon);
      this.active = element;
      let folder = '';//( this.dark)? 'neg/':'';
      this.homeIcon = this.active==='home'?folder+'active-home': folder+'home';
      this.serviceIcon =  this.active==='service'?folder+'active-hand': folder+'hand';
      this.careerIcon =  this.active==='career'?folder+'active-career': folder+'career';
      this.aboutIcon = this.active==='about'?folder+'active-about': folder+'about';
      // this.callChildMethod(this.dark);

        this.$emit('activateSection', element);
    
      // this.callChildMethod(this.dark);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      var line1 = document.getElementsByClassName('line1')[0];
      var line2 = document.getElementsByClassName('line2')[0];
      var dropdown = document.getElementById('dropdown');
      if(this.isMenuOpen){
        // console.log(dropdown);
        dropdown.style.height=(this.tmode)?'175px':'380px';
        dropdown.style.boxShadow= '0 2.5px 5px rgba(0, 0, 0, 0.05)';
        
        line1.classList.add("r45");
        line2.classList.add("rm45");
      }
      if(!this.isMenuOpen){
        dropdown.style.height='104px';
        dropdown.style.boxShadow= '0 2.5px 5px rgba(0, 0, 0, 0.01)';

        line1.classList.remove("r45");
        line2.classList.remove("rm45");
      }
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    moveTouch(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    handleSwipe() {
      if (this.touchStartY - this.touchEndY > 50) {
        // Swipe vers le haut
        this.isMenuOpen = false;
        this.closeMenu(); // Fonction pour gérer l'animation de fermeture
      }
    },
    closeMenu() {
      var dropdown = document.getElementById('dropdown');
      dropdown.style.height = '104px';
      dropdown.style.boxShadow = '0 2.5px 5px rgba(0, 0, 0, 0.01)';
      
      var line1 = document.getElementsByClassName('line1')[0];
      var line2 = document.getElementsByClassName('line2')[0];
      line1.classList.remove("r45");
      line2.classList.remove("rm45");
    }
  },
  watch: {
    touchEndY() {
      this.handleSwipe();
    },
  }
};
</script>
<style scoped>
.fullscreen{
  /* background: #000; */
  height: 100vh;
  width: 100%;
  position: fixed;
  /* z-index: 10; */
}
.ls-burgered{
  position: relative;
  top: 0px;
  left: 0;
}
.line1,.line2{
  border-radius: 2px;
  background: var(--secondary);
  height: 3.1px;
  transition: width 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.line1{
  width: 40px;
}
.line2{
  width: 30px;
}
.r45{
  width: 35px;
  /* transform: rotate(45deg) translateY(180%); */
  transform: rotate(-45deg) translateY(180%);
}
.rm45{
  width: 35px;
  /* transform: rotate(-45deg) translateY(-180%); */
  transform: rotate(45deg) translateY(-180%);
}
.menu-wrapper{
  position: fixed;
  top : 0;
  z-index: 1000;
  background: var(--surface-bright);
  /* height: 100PX; */
  width: 100%;
  /* transition: height 0.5s ease-in-out; */
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
  .picto{
    position: absolute;
    top:36px;
    left:48px;
  }

.burger-button {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end ;
  justify-content: center;
  gap:6px;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  /* top: 64px;
  right: 64px; */
  /* position: fixed; */
}
.close-button {
  top: 41px;
  position: absolute;
  right: 24px;
  font-size: 20px;
  background: none;
  border: none;
  color: var(--on-surface);
  cursor: pointer;
}
#dropdown {
  height: 104px;
  transition: height 0.5s ease-in-out;
   background: var(--surface-bright);
  overflow: hidden; 
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.01);
  width: 100%; 
}
.dropdownMenu {
  width: 100%;
  position: fixed;
  top: 100px;
  right: 0;
  /* height: 100%; */
  /* background: var(--surface-bright); */
  color: var(--on-surface);
  display: flex;
  
  justify-content: center;
  align-items: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 1000;

  /* opacity: 0; */
  /* max-height: 20px; */
  /* overflow: hidden; */
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  /* height: 10px; */
  /* transition: height 0.5s ease-in-out, max-height 0.3s ease, opacity 0.3s ease; */

  animation: bumped 0.8s ease-in-out;

}
@keyframes bumped {
  0%{
    transform: translateY(-10px);
    opacity: 0;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
}
.dropdownMenu.rowlinks ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin: 0 auto;
}
.dropdownMenu ul {

  list-style: none;
  padding: 0;
  padding: 0px 48px 12px 48px;
  /* text-align: center; */
  margin: 0;
  /* margin-top: 64px; */
}

.dropdownMenu ul li {
  margin: 20px 0;
  cursor: pointer;
}
.dropdownMenu.whitetext{
  color: var(--surface-bright);
}
.link:focus{
  color: var(--on-surface);

}.dropdownMenu ul li>a {
  color: var(--secondary);
  font-size: 20px;
  text-decoration: none;
  display: flex;
  gap: 10px;  
  align-items: center;
}
#whitetext{
  color: var(--surface-bright);
}

.visible {
  opacity: 1;
  /* max-height: fit-content; */
  /* height: 234px; */
  /* transition: max-height 0.3s ease, opacity 0.3s ease; */

  /* height: max-content; */
}

.primary-active
{
  background: var(--on-surface);
  color: var(--surface-bright);
  padding: 10px 24px;
  border-radius: 24px;
}

@media (max-width: 1270px) {
  .burger-button {
    /* display: flex;
    flex-direction: column;
    align-items: flex-end ;
    justify-content: center;
    gap:6px;
    cursor: pointer;
    background: none;
    border: none;
    */
    top: 50px;
    position: absolute;
    right: 24px;
    position: fixed;
  }
}
@media (max-width: 773px) {
  .picto{
    position: absolute;
    top:36px;
    left:36px;
  }
}
</style>