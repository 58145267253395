<template>
  <BentoPage/>
  
   <!-- <FAQChat/>  -->
  <!-- <AboutUs/> -->
  <!-- <Services/> -->
   <!-- <FormBox /> -->
  <!-- <BentoBox404 /> -->
  <!-- <Row /> -->
  <!-- <img alt="Vue logo" src="./assets/img/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/>  -->
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'
// import Row from './components/bento/Row.vue';
// import FormBox from './components/bento/FormBox.vue';
// import BentoBox404 from './components/bento/BentoBox404.vue';
// import AboutUs from './components/bento/AboutUs.vue';
// import Services from './components/bento/Services.vue';
// import FAQChat from './components/bento/FAQChat.vue';
// import MenuBurger from './components/bento/MenuBurger.vue';
import BentoPage from './components/bento/BentoPage.vue';

export default {
  name: 'App',
  components: {
    BentoPage,
    // MenuBurger,
    // HelloWorld,
    // Row,
    //  FormBox,
    // BentoBox404,
    // Services,
    // AboutUs,
    // FAQChat
  },
  data() {
        return {
          screenWidth: window.innerWidth
        };
      },
      computed: {
        ws_mode() {
          return (this.screenWidth > 1270); 
        },
        t_mode() {
          return (this.screenWidth < 1270 ); 
        }
      },
      mounted() { //Cycle de vie du composant.
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Appel initial pour définir la largeur correcte
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
      },
      methods: {
        handleResize() {
          this.screenWidth = window.innerWidth;
        }
      }
}
</script>
<style src="./assets/styles/style.css"></style>
