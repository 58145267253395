import { createI18n } from "vue-i18n";
import en from "./en.json";
import fr from "./fr.json";
import mg from "./mg.json";
import ind from "./ind.json";

/*type MessageSchema = typeof en;*/

// Configurer vue-i18n
const i18n = createI18n({
    locale: 'en', // Langue par défaut
    messages: {
      en,
      fr,
      mg,
      ind,
    },
  });

export default i18n;
