import { createRouter, createWebHistory } from 'vue-router';

const Row = () => import('@/components/bento/Row.vue'); // Lazy load
const AboutUs = () => import('@/components/bento/AboutUs.vue'); // Lazy load
const Services = () => import('@/components/bento/Services.vue'); // Lazy load
const FormBox = () => import('@/components/bento/FormBox.vue'); // Lazy load
const FAQChat = () => import('@/components/bento/FAQChat.vue'); // Lazy load
const AppLandingPage = () => import('@/components/bento/AppLandingPage.vue'); // Lazy load
const DagoLandingPage = () => import('@/components/bento/DagoLandingPage.vue'); // Lazy load
const RiderLandingPage = () => import('@/components/bento/RiderLandingPage.vue'); // Lazy load
const FooderLandingPage = () => import('@/components/bento/FooderLandingPage.vue'); // Lazy load
const BentoBox404 = () => import('@/components/bento/BentoBox404.vue'); // Lazy load

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', component: Row },
  { path: '/faq', component: FAQChat },
  { path: '/about', component: AboutUs },
  { path: '/service', component: Services },
  { path: '/career', component: FormBox },
  { path: '/getTheApp', component: AppLandingPage },
  { path: '/getDagoApp', component: DagoLandingPage },
  { path: '/getRiderApp', component: RiderLandingPage },
  { path: '/getFooderApp', component: FooderLandingPage },
  { path: "/:catchAll(.*)", component: BentoBox404 },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
