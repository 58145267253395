<template>
  <div class="fab-container" v-if='(svg!="" && isScrolled) || svg==""'>
    <!-- <div class="huhu"></div> -->
    <button  :class="['fab', background]" @click="handleClick" :style="{ opacity: fabOpacity }">
      <img v-if='svg==""' :src="require(`../../../public/assets/icons/${icon}.png`)" height="40px" class='rounded' alt="">
      <!-- <img v-if='svg!=""' :src="require(`../../../public/assets/icons/svg/${svg}.svg`)" width="25px" class='rounded' alt=""> -->
      <!-- <img src="../../../public/assets/icons/mdi_chatbot.svg" class='darkmode' height="48px"  alt=""> -->
      <svg v-if='svg!="" && isScrolled' style='transform: rotate(180deg)' width="40" height="9" viewBox="0 0 41 9" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M13.8872 1.5L20.8872 7.5L27.8872 1.5" stroke="#171D1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <i :class="iconClass"></i>
    </button>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isScrolling: false,
      isScrolled: false,
      fabOpacity: 1
    };
  },
  props: {
    icon: {
      type: String,
      default: "mdi_blank"
    },
    svg: {
      type: String,
      default: ""
    },
    background: {
      type: String,
      default: "secondary"
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 100;
      if (!this.isScrolling) {
        this.isScrolling = true;
        this.fabOpacity = 0;

        setTimeout(() => {
          this.isScrolling = false;
          this.fabOpacity = 1;
        }, 150); // Délai après l'arrêt du scroll
      }
    }
  }
};
</script>

<style scoped>

.centered{
  left: 0;
  right: 0;
   position: fixed;
  bottom: 24px;
  /* align-self: center; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
   justify-content: center;
  align-items: center;
  display: flex;
  width: min-content
}
.fab-container {
  z-index: 999;
  position: fixed;
  bottom: 48px;
  right: 20px;
  width: max-content;
}

.rounded{
  border-radius: 100px;
}
.fab {
  /* background: var(--secondary-color); */
  color: white;
  /* border: 1px solid var(--surf-container-highest); */
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 4px 4px var(--on-surface-25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 24px; */
  transition: opacity 0.5s ease-in-out,width 0.5s ease-in-out,height 0.5s ease-in-out;
}

.fab:hover {
  /* background: var(--green); */
  /* opacity: 0.8; */
  /* margin: 0; */
  width: 55px;
  height: 55px;

}
.fab.centered:hover {
  /* background: var(--green); */
  /* opacity: 0.8; */
  /* margin: 0; */
  width: 55px;
  height: 55px;

}
.fab i {
  transition: transform 0.3s ease;
}

.fab.open i {
  transform: rotate(45deg);
}

</style>
