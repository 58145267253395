<template>
    <div 
    :class="[{'icon-lg':wsmode},{'icon-lg row': mmode || tmode},{'reverse':!isEng && toggleClick}]" :style="{ opacity: fabOpacity }" >
         <!-- <div   class="flag-text flag"
          v-show="toggleClick || selectedLanguage==='en'" 
          @click="setLanguage('en')">
        EN</div>
        <div class="flag-text flag"
            v-show="toggleClick || selectedLanguage==='fr'" 
            @click="setLanguage('fr')" >FR</div> -->
       <p hidden v-if=!wsmode class='selected'>{{selectedLanguage}}</p>
        <MDIcon icon="EN"
        v-show="toggleClick || selectedLanguage==='en'" 
        alt="English" 
        @click="setLanguage('en')"
        :class="selectedLanguage=='en'?'active':'flag'"
         />
        <MDIcon icon="FR" 
        v-show="toggleClick || selectedLanguage==='fr'" 
        alt="Français"
        @click="setLanguage('fr')"
        :class="selectedLanguage=='fr'?'active':'flag'"
        />
        <MDIcon icon="MG" 
        v-show="toggleClick || selectedLanguage==='mg'" 
        alt="Malagasy"
        @click="setLanguage('mg')"
        :class="selectedLanguage=='mg'?'active':'flag'"
        />
        <MDIcon icon="IND" 
        v-show="toggleClick || selectedLanguage==='ind'" 
        alt="Indonesia"
        @click="setLanguage('ind')"
        :class="selectedLanguage=='ind'?'active':'flag'"
        />
        <!-- <img 
        v-show="toggleClick || selectedLanguage==='en'" 
        src="assets/icons/EN.svg" 
        alt="English" 
        @click="setLanguage('en')"
        :class="selectedLanguage=='en'?'active':'flag'"
        /> 
        <img 
        v-show="toggleClick || selectedLanguage==='fr'" 
        src="assets/icons/FR.svg" 
        alt="Français"
        @click="setLanguage('fr')"
        :class="selectedLanguage=='fr'?'active':'flag'"
        /> <img 
        v-show="toggleClick || selectedLanguage==='mg'" 
        src="assets/icons/MG1.svg" 
        alt="Malagasy"
        @click="setLanguage('mg')"
        :class="selectedLanguage=='mg'?'active':'flag'"
        /> -->
    </div>
  </template>
  
  <script>
import MDIcon from './MDIcon.vue';

  export default {
    name: 'LanguageSelector',
      components:{
        MDIcon,
      },
    props:{
      wsmode:{
        type: Boolean,
        default: false
      },
      tmode:{
        type: Boolean,
        default: false
      },
      mmode:{
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        selectedLanguage: this.$i18n.locale,
        toggleClick: false,
        isEng: true,
        isScrolling: false,
        fabOpacity: 1
      };
    }, 
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      setLanguage(lang) {
        this.$i18n.locale = lang; 
        this.toggleClick = !this.toggleClick;
        this.selectedLanguage = lang;
        this.isEng = this.selectedLanguage==='en';
      },
      handleScroll() {
        if (!this.isScrolling) {
          this.isScrolling = true;
          this.fabOpacity = 0;

          setTimeout(() => {
            this.isScrolling = false;
            this.fabOpacity = 1;
          }, 150); // Délai après l'arrêt du scroll
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .selected{
    margin: 0;
  }
  .flag-text{
      font-size: 20px;
      color: var(--secondary);
   }
 
  .flags{
    width:27px;
  }
  .icon-lg{
    height: 40px;
    position: absolute;
    top: 48px;
    right:96px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: fixed;
    z-index: 999;
    /* justify-content: center; */
    transition: opacity 0.5s ease-in-out,width 0.5s ease-in-out,height 0.5s ease-in-out;

  }
  .icon-lg.row{
    flex-direction: row;
    flex-direction: row;
    width: 66px;
  }
.reverse{
  margin-top: 3.5px;
    display: flex;
    /* flex-direction: column-reverse; */

    /* justify-content: flex-start; */

  }
  .active {
    opacity: 1;
    cursor: pointer;
    /* height: 16px; */
  }
  .flag {
    cursor: pointer;
    /* height: 14px; */
    float: left;
    opacity: 0.45;
  }
  .flag,.active{
    height: 15px;
  }
  .french-flag {
    margin-top: 20px;
  }
  </style>
  